.team-status-item-hover:hover {
  opacity: 1 !important;
  background-color: lightgray !important;
}

.team-status-item {
  background-color: lightgray !important;
}

.items-loading-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}
