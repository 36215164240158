@import '~leaflet-draw/dist/leaflet.draw.css';
.leaflet-draw-actions {
  display: none !important;
}
.leaflet {
  &-container {
    font: inherit;
    font-size: 14px;
  }

  &-popup {
    margin-bottom: 58px;

    &-content {
      min-width: 200px;
      margin: 20px;
      text-align: center;

      &-wrapper {
        border-radius: 10px;
      }

      h2 {
        font-weight: 300;
      }

      strong {
        display: inline-block;
        margin-top: 3px;
      }

      a {
        text-decoration: none;
      }
    }

    &-close-button {
      top: 3px !important;
      right: 3px !important;
    }
  }

  &-control-layers-list {
    padding: 5px 0;

    label {
      padding: 5px 10px;
    }
  }

  &-top {
    z-index: 900;
  }
}
