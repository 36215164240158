.messages {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  align-content: space-around;

  &-item {
    background-color: #f5f5f5;
    margin: 0 0 20px 0;
    padding: 15px;
  }

  &-item:last-child {
    margin-bottom: 0;
  }

  &-content {
    padding: 15px 0 0 0;
    margin: 0;
    word-wrap: break-word;

    P {
      margin: 0;
      padding: 0;
    }
  }

  &-attachments {
    padding: 15px 0 0 0;

    &-file {
      display: inline-block;
      margin: 10px 10px 0 0;
      right: 10px;
      padding: 7px;
      background: #b0bec5;
      border-radius: 20px;

      &:link,
      &:hover {
        text-decoration: unset;
        color: #ffffff;
      }

      &-new {
        background: #e4e4e4;
        padding-right: 30px;

        &:link,
        &:hover {
          color: #2196f3;
        }
      }
    }

    &-delete {
      position: absolute;
      margin: -2px 0 0 4px;
    }

    &-progress {
      width: 22px !important;
      height: 22px !important;
      position: absolute;
      margin: -2px 0 0 4px;
    }
  }

  &-form {
    padding: 20px 0 0 0;

    &-attachment {
      float: right;
    }

    &-attachment:hover {
      cursor: pointer;
    }

    &-button {
      float: right;
      margin-top: 15px;
    }

    &-resolve-button {
      float: left;
      margin-top: 15px;
    }
  }
}
