.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 15px;
}

.sign-map-container {
  max-width: unset;
}

.sign-map-container .leaflet-container {
  height: calc(100vh - 185px - 59px) !important;
}

.select-component > DIV > DIV {
  min-height: auto;
}

.list-actions-container {
  padding: 33px 25px 20px 25px;
  flex-wrap: nowrap;
}

.collapsable-expansion-panel {
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1) !important;
  margin: 0 !important;
}

.collapsable-expansion-panel:before {
  background: inherit !important;
}

.collapsable-expansion-title {
  font-family: Roboto;
  font-style: normal;
  font-size: 1.2em;
  color: #333333;
  margin: 10px 0;
  width: 100%;
}

.more-info-block {
  font-size: 0.875rem;
  font-weight: 300;
  font-style: italic;
}

.MuiTableRow-root TBODY TR:hover {
  background: #f5f500 !important;
}

.MuiTableCell-body {
  overflow-wrap: anywhere;
}

.table-lines TBODY TR:nth-of-type(odd) {
  background: rgba(0, 0, 0, 0.04) !important;
}

.table-lines TBODY TR:hover {
  background: rgba(0, 0, 0, 0.07) !important;
  cursor: pointer;
}
