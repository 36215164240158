@import './variables';

.tile-select {
  margin: 25px 40px 60px;
}

.tile-select__input {
  display: none;
}

.tile-select__label {
  border: solid thin $color-background;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 25px;
  transition: all 0.3s ease-out;
  width: 100%;
  height: 100%;

  &:hover {
    border-color: $color-primary;
  }

  .tile-select__input:checked + & {
    background-color: rgba($color-primary, 0.1);
    border-color: $color-primary;
  }
}
