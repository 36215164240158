.red-button {
  background: #ed1e43 !important;
}

.red-button:hover {
  background: #c21f39 !important;
  transition: all 250ms;
}

.black-button {
  background: #4b4b4b !important;
}

.black-button:hover {
  background: #767676 !important;
  transition: all 250ms;
}

.white-button {
  background: #ffffff !important;
}

.gray-button {
  background: #d5d5d5 !important;
}

.white-button:hover {
  background: #dadada !important;
  transition: all 250ms;
}

.link-button {
  background: #f0f5ff !important;
  &:link {
    text-decoration: underline;
  }
}

.link-button:hover {
  background: #c9defc !important;
  transition: all 250ms;
}

.red-button SPAN,
.black-button SPAN {
  color: #fff;
}

.button-margin-left {
  margin-left: 15px !important;
}

.image-popup-button {
  background: #fff !important;
  transform: scaleY(0.85) scaleX(0.85);
}

.image-popup-button:hover {
  background: #dadada !important;
  transition: all 250ms;
}

.close-image-popup-button {
  position: absolute !important;
  right: 15px;
  top: 15px;
}

.full-screen-image-popup-button {
  position: absolute !important;
  right: 15px;
  bottom: 15px;
}

.prev-image-popup-button {
  position: absolute !important;
  left: 15px;
  top: 50%;
}

.next-image-popup-button {
  position: absolute !important;
  right: 15px;
  top: 50%;
}

.fab-small {
  transform: scaleY(0.85) scaleX(0.85);
}

.fab-marging {
  margin: 5px !important;
}

.white-fab {
  background: #fff !important;
}

.white-fab:hover {
  background: #dddddd !important;
  transition: all 250ms;
}

.grey-fab {
  background: #dddddd !important;
}

.grey-fab:hover {
  background: #b9b9b9 !important;
  transition: all 250ms;
}

.viod-link {
  display: inline-flex;
  justify-self: flex-start;
  align-items: center;
}

.viod-link > DIV {
  display: inline;
  margin-left: 5px;
}

.image-check {
  background: white;
  border-radius: 3px;
  height: 24px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 24px;
  z-index: 99;
}

.pointer {
  cursor: pointer;
}

.pdf {
  width: 100px;
  height: 100px;
}

.list-edit-button {
  min-width: 40px !important;
}
