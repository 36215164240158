@import '~react-datepicker/dist/react-datepicker.css';

.react-datepicker {
  &__input-container {
    input {
      text-align: left;
    }
  }

  &__day {
    &--outside-month {
      color: grey !important;
    }
  }

  &-popper {
    left: -15px !important;
  }

  &__triangle {
    left: 120px;
  }
}
