.old-planning-map-link {
  position: absolute;
  z-index: 999;
  bottom: 0;
  left: 0;
  background-color: #14961e;
  margin: 0;
  padding: 3px 5px;
  border: 1px solid black;
  border-radius: 2px;

  a {
    color: white;
    text-decoration: none;
  }
}

.planning-tours-container {
  padding: 20px;
}

.planning-tours-loader-wrapper {
  margin-top: 20px;
}

.planning-grid {
  flex: 1;
  overflow: hidden;
}

.planning-tours-grid {
  height: inherit;
  overflow: auto;
}

.planning-signs-count {
  font-size: 12px;
}
