.team-assign-dialog {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  min-width: 300px;
}
.team-dialog-team {
  width: 20px;
  font-size: inherit;
}

div[role='tooltip'] {
  opacity: 1 !important;
}

.alert-warning {
  color: #f44336;
  border: 1px solid #f44336;
  margin: 30px 0;
  padding: 20px;
}
