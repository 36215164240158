$small-color: #1ccc65;
$medium-color: #e4c600;
$large-color: #b9090d;

.marker {
  position: relative;

  &::before {
    content: none;
    position: absolute;
    background-color: rgba(#1e88e5, 0.4);
    height: 6px;
    width: 6px;
    bottom: -3px;
    left: calc(50% - 3px);
    border-radius: 50%;
  }

  svg {
    position: relative;
  }
}

.marker-cluster-small {
  background-color: rgba($small-color, 0.5);
  div {
    background-color: rgba($small-color, 0.8);
  }
}

.marker-cluster-medium {
  background-color: rgba($medium-color, 0.5);
  div {
    background-color: rgba($medium-color, 0.8);
  }
}

.marker-cluster-large {
  background-color: rgba($large-color, 0.5);
  div {
    background-color: rgba($large-color, 0.8);
  }
}
