.reordering {
  &-header {
    background: #ffffff;

    &-back {
      display: block;
      width: 58px;
      height: 58px;
      padding: 17px 0 0 17px;
      float: left;
      cursor: pointer;
    }

    &-title {
      float: left;
      font-size: 160%;
      height: 58px;
      line-height: 58px;
      padding: 0 30px 0 0;
    }

    &-warning {
      float: right;
      height: 58px;
      line-height: 58px;
    }
  }

  &-list {
    &-container {
      padding: 20px;

      > H2 {
        margin: 0 0 5px 0;
      }
    }

    &-item-container {
      clear: both;
    }

    &-date {
      font-size: 130%;
      margin-bottom: 8px;
      margin-right: 100px;
      float: left;
    }

    &-updated-by {
      font-size: 90%;
      margin-bottom: 8px;
      margin-right: 100px;
      float: left;
    }

    &-updated-at {
      font-size: 90%;
      margin-bottom: 25px;
      margin-right: 100px;
      float: left;
    }

    &-loading {
      width: 50px;
      float: right;
      transform: scale(0.7);
    }

    &-item {
      background: #fff;
      border-radius: 8px;
      color: #000;
      flex: 1;
      margin-bottom: 2px;
      padding: 4px 10px 4px 10px;
      height: 40px;
      line-height: 30px;
      text-overflow: ellipsis;
      cursor: pointer;
      border: 1px solid #fff;
    }

    &-item:hover {
      background: #f1f3ff;
      border: 1px solid #6078fa;
    }

    &-sequence {
      display: block;
      text-align: center;
      horiz-align: center;
      line-height: 28px;
      width: 30px !important;
      height: 30px;
      border: 1px solid #dddddd;
      border-radius: 5px;
    }

    &-checkbox {
      display: block;
      text-align: center;
      horiz-align: center;
      line-height: 38px;
      width: 30px !important;
      height: 30px;
      background: #f1f3ff;
      border: 1px solid #dbdff5;
      border-radius: 5px;
      color: #6078fa;
      margin-left: 4px;

      &-checked {
        background: #6078fa;
        border: 1px solid #6078fa;
        color: #fff;
      }
    }

    &-address {
      flex: 5;
      padding-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-type {
      flex: 1;
      display: inline-flex;
      text-align: right;
      white-space: nowrap;
      font-size: 16px;
    }

    &-typeicon {
      color: #a9a9a9;
      transform: scale(0.8);
    }
  }

  &-popup {
    &-sequence {
      display: inline-block;
      width: 40px !important;
      height: 33px;
      line-height: 33px;
      text-align: center;
      border: 1px solid #dddddd;
      border-radius: 5px;
    }

    &-save {
      display: inline-block;
      height: 33px;
      line-height: 33px;
      margin-left: 4px;
      padding: 0 12px 0 12px;
      text-align: center;
      background: #f1f3ff;
      border: 1px solid #dbdff5;
      border-radius: 5px;
      color: #6078fa;
      font-size: 16px;
      font-weight: bold;

      &:hover {
        background: #f1f3ff;
        border: 1px solid #6078fa;
        cursor: pointer;
      }
    }
  }
}
